
import { api } from "@/api/api";
import { ApiGetCustomerPersonAddressListDto, ApiSortOrder } from "@/api/generated/Api";
import BaseLayout from "@/components/shared/BaseLayout.vue";
import BaseModal from "@/components/shared/BaseModal.vue";
import BaseTableFiltered from "@/components/shared/table/BaseTableFiltered.vue";
import { getInitialModalData, useOpenModal } from "@/fragments/modal/useOpenModal";
import { AddressBookRouteNames } from "@/shared/enums/RouteNames/addressBookRouteNames.enum";
import { ModalType } from "@/shared/enums/modalTypeEnum";
import { ModalBaseData } from "@/shared/interfaces/ModalBaseData.interface";
import { PaginationQueryInterface } from "@/shared/interfaces/PaginationQuery.interface";
import { PaginationValues } from "@/shared/interfaces/TablePaginationInterface";
import { computed, defineComponent, onMounted, ref, watch } from "@vue/composition-api";
import debounce from "lodash.debounce";
import { DataOptions } from "vuetify";
import { VSwitch, VTextField } from "vuetify/lib";
import EditContactInformationModal from "./EditContactInformationModal.vue";

export default defineComponent({
  name: "ContactsPeoplePage",
  components: {
    BaseTableFiltered,
    EditContactInformationModal,
    BaseModal,
    BaseLayout,
  },
  setup() {
    const pageState = ref<DataOptions>({
      page: 1,
      itemsPerPage: 30,
      sortBy: [],
      groupBy: [],
      groupDesc: [],
      multiSort: false,
      mustSort: false,
      sortDesc: [],
    });
    const query = ref<PaginationQueryInterface>({
      PageNumber: pageState.value.page,
      PageSize: pageState.value.itemsPerPage,
    });
    const paginationValues = ref<PaginationValues>({
      isLoading: false,
      pageCount: 0,
      serverItemsLength: 0,
    });
    const persons = ref<ApiGetCustomerPersonAddressListDto[]>();

    const modalData = ref<ModalBaseData>(getInitialModalData());

    watch(
      query,
      () => {
        debounceFetch();
      },
      { deep: true }
    );

    const updatePagination = (paginationData: any) => {
      pageState.value = paginationData;
      query.value = {
        ...query.value,
        PageNumber: pageState.value.page,
        PageSize: pageState.value.itemsPerPage > 0 ? pageState.value.itemsPerPage : undefined,
        SortBy: pageState.value.sortBy[0], // API doesn't support multi-sort. Multi-sort is disabled on the table
        SortOrder:
          pageState.value.sortDesc.length === 0
            ? undefined
            : pageState.value.sortDesc[0]
            ? ApiSortOrder.ApiDescending
            : ApiSortOrder.ApiAscending,
      };
    };

    const fetchPersons = async () => {
      paginationValues.value.isLoading = true;
      const response = await api.customer.getCustomerPersonAddressList(query.value);
      paginationValues.value = {
        isLoading: false,
        pageCount: response.data.totalPages,
        serverItemsLength: response.data.totalCount,
      };
      persons.value = response.data.items || [];
    };

    const debounceFetch = debounce(fetchPersons, 500);

    const toCustomerPersonPage = (id: number) => ({
      name: AddressBookRouteNames.CustomerPerson,
      params: { id: id.toString() },
    });

    const openEditPersonInfo = useOpenModal(ModalType.Edit, "Kontaktinformasjon", modalData);

    const closeModal = () => {
      modalData.value.showModal = false;
    };

    const updateQuery = (key: string, value?: string) => {
      query.value = {
        ...query.value,
        PageNumber: 1,
        [key]: value,
      };
      pageState.value.page = 1;
    };

    const filter = computed(() => [
      {
        component: VTextField,
        value: "firstName",
        default: undefined,
        attrs: {
          label: "Fornavn",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.FirstName) || query.value.FirstName === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("FirstName", model);
          } else if (!model || model.length === 0) {
            updateQuery("FirstName");
          }
        },
      },
      {
        component: VTextField,
        value: "lastName",
        default: undefined,
        attrs: {
          label: "Etternavn",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.LastName) || query.value.LastName === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("LastName", model);
          } else if (!model || model.length === 0) {
            updateQuery("LastName");
          }
        },
      },
      {
        component: VTextField,
        value: "email",
        default: undefined,
        attrs: {
          label: "E-post",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.Email) || query.value.Email === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("Email", model);
          } else if (!model || model.length === 0) {
            updateQuery("Email");
          }
        },
      },
      {
        component: VTextField,
        value: "mobileNumber",
        default: undefined,
        attrs: {
          label: "Telefon",
          dense: true,
          hideDetails: true,
          clearable: true,
        },
        apply: (value: any, model: any) => {
          if ((!model && !query.value.MobileNumber) || query.value.MobileNumber === model) {
            return;
          }
          if (model && model.length >= 2) {
            updateQuery("MobileNumber", model);
          } else if (!model || model.length === 0) {
            updateQuery("MobileNumber");
          }
        },
      },
      {
        component: VSwitch,
        value: "isActive",
        default: false,
        staticClass: "mx-3",
        attrs: {
          inset: true,
          label: "Vis inaktive",
        },
        apply: (value: any, model: any) => (query.value.IncludeInactive = model || false),
      },
    ]);

    onMounted(async () => {
      await fetchPersons();
    });

    return {
      headers,
      persons,
      filter,
      toCustomerPersonPage,
      updatePagination,
      pageState,
      paginationValues,
      modalData,
      openEditPersonInfo,
      closeModal,
    };
  },
});

const headers = [
  {
    text: "Fornavn",
    value: "firstName",
  },
  {
    text: "Handlinger",
    value: "actions",
    sortable: false,
  },
  {
    text: "Etternavn",
    value: "lastName",
  },
  {
    text: "E-post",
    value: "email",
  },
  {
    text: "Telefon",
    value: "mobileNumber",
  },
  {
    text: "Brukertype",
    value: "customerPersonType",
  },
  {
    text: "Aktiv",
    value: "isActive",
  },
];
