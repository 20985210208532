var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.modalData.showModal ? _c('BaseModal', {
    on: {
      "close": _vm.closeModal
    }
  }, [_c('EditContactInformationModal', {
    attrs: {
      "headline": _vm.modalData.modalHeadline,
      "modalType": _vm.modalData.modalType,
      "existingItemModal": _vm.modalData.existingItem,
      "existingItemId": _vm.modalData.existingItemId,
      "elementId": _vm.modalData.existingItemId,
      "defaultValues": _vm.modalData.defaultValues
    },
    on: {
      "close": _vm.closeModal
    }
  })], 1) : _vm._e(), _c('BaseLayout', {
    scopedSlots: _vm._u([{
      key: "title",
      fn: function fn() {
        return [_c('span', {
          attrs: {
            "data-cy": "titleAdressebok"
          }
        }, [_vm._v(" Adressebok - Personer ")])];
      },
      proxy: true
    }])
  }, [[_c('v-form', [_c('BaseTableFiltered', {
    staticClass: "mt-3",
    attrs: {
      "headers": _vm.headers,
      "items": _vm.persons,
      "filter": _vm.filter,
      "options": _vm.pageState,
      "pageCount": _vm.paginationValues.pageCount,
      "server-items-length": _vm.paginationValues.serverItemsLength,
      "loading": _vm.paginationValues.isLoading,
      "multiSort": false,
      "hasServerSidePagination": true
    },
    on: {
      "update:options": _vm.updatePagination
    },
    scopedSlots: _vm._u([{
      key: "item.isActive",
      fn: function fn(_ref) {
        var value = _ref.value;
        return [_vm._v(" " + _vm._s(value ? "Aktiv" : "Inaktiv") + " ")];
      }
    }, {
      key: "item.customerPersonType",
      fn: function fn(_ref2) {
        var value = _ref2.value;
        return [_vm._v(" " + _vm._s(value === "b2c" ? "Minside" : "Begrenset bruker") + " ")];
      }
    }, {
      key: "actions",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref4) {
              var on = _ref4.on,
                  attrs = _ref4.attrs;
              return [_c('v-btn', _vm._g(_vm._b({
                staticClass: "mr-2",
                attrs: {
                  "icon": "",
                  "data-cy": "displayCustomerPerson",
                  "to": _vm.toCustomerPersonPage(item.id)
                }
              }, 'v-btn', attrs, false), on), [_c('v-icon', [_vm._v("mdi-open-in-new")])], 1)];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Vis " + _vm._s("".concat(item.firstName, " ").concat(item.lastName)))])]), _c('v-tooltip', {
          attrs: {
            "bottom": ""
          },
          scopedSlots: _vm._u([{
            key: "activator",
            fn: function fn(_ref5) {
              var on = _ref5.on,
                  attrs = _ref5.attrs;
              return [_c('v-icon', _vm._g(_vm._b({
                staticClass: "mr-2",
                on: {
                  "click": function click($event) {
                    return _vm.openEditPersonInfo({
                      id: item.id
                    }, "kontaktinformasjon for ".concat(item.firstName, " ").concat(item.lastName), item);
                  }
                }
              }, 'v-icon', attrs, false), on), [_vm._v(" mdi-pencil ")])];
            }
          }], null, true)
        }, [_c('span', [_vm._v("Rediger " + _vm._s("".concat(item.firstName, " ").concat(item.lastName)))])])];
      }
    }], null, true)
  })], 1)]], 2)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }